import request from '@/utils/request'

//-------------门禁管理---------

//获取设备列表
export function eqQueryPage(data) {
  return request({
    url: '/wlw_DoorCode/queryPage',
    method: 'post',
    params: data
  })
}

//设备修改
export function eqUpdate(data) {
  return request({
    url: '/wlw_DoorCode/update',
    method: 'post',
    params: data
  })
}


//获取日志列表
export function logQueryPage(data) {
  return request({
    url: '/wlw_DoorOpenlog/queryPage',
    method: 'post',
    params: data
  })
}


//开锁记录
export function openDoorList(data) {
  return request({
    url: '/wlw_SysuserDoor/QueryDoorList',
    method: 'post',
    params: data
  })
}

//设备监控
export function eqOnline(data) {
  return request({
    url: '/wlw_SysuserDoor/QueryDoorOnlineList',
    method: 'post',
    params: data
  })
}

