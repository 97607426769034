<template>
  <div class="box1">
    <div class="title">
      <div>
        <span>异常</span>
        <div class="yichang"></div>
      </div>
      <div>
        <span>正常</span>
        <div class="zhengchang"></div>
      </div>
    </div>
    <div class="shebei-list">
      <div class="shebei" v-for="item in tableData1" :key="item.index">
        <div v-show="item.online == 0" class="yichang"></div>
        <div v-show="item.online == 1" class="zhengchang"></div>
        <div class="shebei-text">
          <div>{{ item.doorcodename }}</div>
          <div>{{ item.sysTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eqOnline } from '@/api/entranceguard'
export default {
  mounted() {
    this.getList();
  },
  computed: {},
  data() {
    return {
      tableData1: [], //获取的表格数据
    };
  },
  methods: {
    getList() {
			eqOnline({
          userid: localStorage.getItem("userid"),
        }).then((res) => {
        if (res.result == 200) {
          res.detail.map((item, index) => {
            item.id = index;
          });
          this.tableData1 = res.detail;
          // console.log(res);
        } else {
          this.$message.error(res.detail.description);
        }
      });
    }, //获取表格数据和查询请求
  },
};
</script>

<style lang="scss" scoped>
  .title {
    padding: 50px;
  }
</style>

<style lang='scss'>
.box1 {
	padding: 50px 10px;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    justify-content: flex-end;
    div {
      display: flex;
      align-items: center;
    }
    .yichang {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: red;
      margin: 0 10px;
    }
    .zhengchang {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: green;
      margin: 0 10px;
    }
  }
  .shebei-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    box-sizing: border-box;
    .shebei {
      display: flex;
      align-items: center;
      margin: 10px;
    }
    .shebei-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .yichang {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background-color: red;
      margin: 0 10px;
    }
    .zhengchang {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background-color: green;
      margin: 0 10px;
    }
  }
}
</style>


